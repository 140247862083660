import React from 'react';
import CircleArrowButton from '../ui/circleArrowButton';
import * as buttonStyle from '../ui/circleButton.module.scss';
import * as style from './mapOverlay.module.scss';

const CloseOverlayButton = props => {
  return (
    <div
      className={`${
        props.moved ? style.closeOverlayMoved : style.closeOverlay
      } ${buttonStyle.buttonHoverContainer}`}
    >
      <div
        className={`${style.buttonWrap} ${
          style['btn-' + props.transitionState]
        }`}
      >
        <span className={style.buttonDisplay}>
          <span className={style.buttonCircle}>
            <CircleArrowButton direction="left" active handleClick={null} />
          </span>
          <span className={`${style.closeOverlayLabel}`}>
            {props.buttonText}
          </span>
        </span>
        <button
          className={style.button}
          onClick={props.handleClick}
          aria-label={props.buttonText}
        />
      </div>
    </div>
  );
};

export default CloseOverlayButton;
