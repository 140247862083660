import React from 'react';
import nelsonLogo from '../../assets/ccr-nelson-logo.png';
import translations from '../../utils/translations';
import * as style from './mapInfo.module.scss';
import MapOverlay from './mapOverlay';

const MapInfoText = props => {
  return (
    <MapOverlay
      layout="CENTER"
      hideHeader={props.hideHeader}
      showHeader={props.showHeader}
      handleClose={props.toggleCredits}
      buttonText={translations.t('Back')}
      transitionState={props.transitionState}
    >
      <div
        className={`${style.creditsText} ${
          style['credits-' + props.transitionState]
        } text-block--fill f-small`}
      >
        <div className={`typeFonts1 typeColorDark1 text-block`}>
          <div dangerouslySetInnerHTML={{ __html: props.content }} />
          {props.mobileBluecadet && (
            <p className={style.mobileAboutCredit}>
              <a
                href="https://www.bluecadet.com"
                target="_blank"
                rel="noopener"
              >
                {translations.t('Site by Bluecadet')}
              </a>
            </p>
          )}
          {props.addNelsonLogo && (
            <div className={style.logoWrap}>
              <a
                href="https://nelson.wisc.edu/ccr/"
                target="_blank"
                rel="noopener"
              >
                <img
                  src={nelsonLogo}
                  alt={translations.t('Center for Climatic Research, Nelson Institute, University of Wisconsin-Madison')}
                />
              </a>
            </div>
          )}
        </div>
      </div>
    </MapOverlay>
  );
};

const MapInfo = props => {
  return <div>{props.active && <MapInfoText {...props} />}</div>;
};

export default MapInfo;
