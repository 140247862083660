import React from 'react';
import * as style from './infoButton.module.scss';

const InfoButton = props => (
  <button
    onClick={props.handleClick}
    className={`${style.infoButton} ${props.showSm ? style.showSm : ''}`}
  >
    <span>{props.text}</span>
  </button>
);

export default InfoButton;
