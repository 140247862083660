import React, { Component } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import * as style from './textAnimator.module.scss';

const TextAnimator = props => {
  return (
    <div
      className={`${style.wrap} ${props.delay ? 'textSlideDelay' : ''} ${
        props.fast ? 'textSlideFaster' : ''
      }`}
    >
      <TransitionGroup>
        {props.show && (
          <CSSTransition key={props.text} timeout={1250} classNames="textSlide">
            <div className={style.item} style={props.style}>
              {props.text}
            </div>
          </CSSTransition>
        )}
      </TransitionGroup>
    </div>
  );
};

export default TextAnimator;
