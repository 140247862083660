import React, { Component } from 'react';
import CloseOverlayButton from './closeOverlayButton';
import * as style from './mapOverlay.module.scss';
import VideoBackground from '../basic/videoBackground';
import LazyImage from '../ui/lazyImage';
const baseOpacity = 0.6;
const scrollTriggerHeight = 50;

class MapOverlay extends Component {
  constructor() {
    super();
    this.scrollAmount = 0;
    this.prevScrollAmount = 0;
    this.state = {
      imgOpacity: baseOpacity,
      imgTop: 0,
      buttonMoved: false,
    };
  }
  componentDidMount() {
    this.rafID = window.requestAnimationFrame(this.updateDisplay);
    this.updateImageStyle();
    this.props.hideHeader();
  }
  componentWillReceiveProps(nextProps) {
    if (
      this.props.transitionState !== nextProps.transitionState &&
      nextProps.transitionState === 'entering'
    ) {
      this.props.hideHeader();
      this.container.scrollTop = 0;
    }

    // hide header on start of exit, not just on willUnmount,
    // otherwise it's delayed
    if (
      this.props.transitionState !== nextProps.transitionState &&
      nextProps.transitionState === 'exiting'
    ) {
      this.props.showHeader();
    }
  }
  componentWillUnmount() {
    window.cancelAnimationFrame(this.rafID);
    this.props.showHeader();
  }
  updateImageStyle() {
    if (this.props.img) {
      this.img.setAttribute(
        'style',
        `opacity: ${this.getImgOpacity()}; transform: translate3d(0, ${this.getImgTop()}px, 0);`
      );
    }
  }
  updateDisplay = () => {
    this.scrollAmount = this.container.scrollTop;
    if (this.scrollAmount !== this.prevScrollAmount) {
      this.updateImageStyle();
    }
    if (!this.state.buttonMoved && this.scrollAmount > scrollTriggerHeight) {
      this.setState({ buttonMoved: true });
    } else if (
      this.state.buttonMoved &&
      this.scrollAmount < scrollTriggerHeight
    ) {
      this.setState({ buttonMoved: false });
    }
    this.prevScrollAmount = this.scrollAmount;
    this.rafID = window.requestAnimationFrame(this.updateDisplay);
  };
  getImgOpacity() {
    const opacity = baseOpacity - this.scrollAmount / 500;
    return opacity < 0 ? 0 : opacity;
  }
  getImgTop() {
    return this.scrollAmount > 0 ? -Math.floor(this.scrollAmount / 2) : 0;
  }
  handleScroll = e => {
    this.scrollAmount = e.target.scrollTop;
  };
  render() {
    return (
      <div
        className={`${
          this.props.layout === 'CENTER'
            ? style.mapOverlayCenter
            : style.mapOverlay
        } ${style['bk-' + this.props.transitionState]}`}
      >
        <div className={`${style.overlayInner} basic-content`}>
          <CloseOverlayButton
            handleClick={this.props.handleClose}
            moved={this.state.buttonMoved}
            buttonText={this.props.buttonText}
            transitionState={this.props.transitionState}
          />
          <div
            className={style.overlayContentWrap}
            ref={el => {
              this.container = el;
            }}
          >
            <div className={`${style.overlayContent}`}>
              <div
                className={`${style.overlayImgWrap} ${
                  style['img-' + this.props.transitionState]
                }`}
                ref={el => {
                  this.img = el;
                }}
              >
                {this.props.videoBackground ? (
                  <div className={style.overlayVideo}>
                    <VideoBackground src={this.props.videoBackground} />
                  </div>
                ) : (
                  <div className={style.overlayImg}>
                    {this.props.img && (
                      <LazyImage
                        src={this.props.img.src}
                        alt={this.props.img.alt}
                        width={745}
                        height={505}
                        fitSize
                      />
                    )}
                  </div>
                )}
              </div>

              {this.props.children}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default MapOverlay;
