import React from 'react';
import { Transition, TransitionGroup } from 'react-transition-group';
import MapInfo from './mapInfo';

const InteractiveCredits = props => (
  <TransitionGroup component="span">
    {props.reveal && (
      <Transition key={'mapInfo'} timeout={1200}>
        {state => (
          <MapInfo
            hidden={props.hidden}
            toggleCredits={props.toggleCredits}
            content={props.content}
            hideHeader={props.hideHeader}
            showHeader={props.showHeader}
            transitionState={state}
            active={true}
            mobileBluecadet={props.mobileBluecadet}
            addNelsonLogo={props.addNelsonLogo}
          />
        )}
      </Transition>
    )}
  </TransitionGroup>
);

export default InteractiveCredits;
