// extracted by mini-css-extract-plugin
export var infoButtonHidden = "mapInfo-module--infoButtonHidden--C8Xb-";
export var creditsText = "mapInfo-module--creditsText--BKBti";
export var creditsEntering = "mapInfo-module--credits-entering--1No6i";
export var btnSlideInUp = "mapInfo-module--btnSlideInUp--LhRSs";
export var btnSlideIn = "mapInfo-module--btnSlideIn--vqVU4";
export var creditsExiting = "mapInfo-module--credits-exiting--SRU8A";
export var btnSlideOutDown = "mapInfo-module--btnSlideOutDown--msnjX";
export var btnSlideOut = "mapInfo-module--btnSlideOut--s+NQd";
export var mobileAboutCredit = "mapInfo-module--mobileAboutCredit--k2uaz";
export var logoWrap = "mapInfo-module--logoWrap--6WDtB";
export var slideUp = "mapInfo-module--slideUp--zs9WM";
export var slideUpLarge = "mapInfo-module--slideUpLarge--XqyHk";